import closetImg from "./img/closet.svg";
import modelImg from "./img/model.svg";
import logo from "./img/vise.svg";
import "aos/dist/aos.css";
import "./index.css";


function App() {
  const scrollToSection = (id: any) => {
    const section = document.getElementById(id);
    const headerHeight = document.querySelector("header")!.offsetHeight;
    const extraMargin = 50;
    if (section) {
      window.scrollTo({
        top: section.offsetTop - headerHeight - extraMargin,
        behavior: "smooth",
      });
    }
  };
  const lightThemeStyle = {
    colorScheme: "light" as "light", // Garantindo o uso do tema claro
  };

  return (
    <>
    <div style={lightThemeStyle}>
      {/* Header com sombra */}
      <header className="fixed top-0 left-0 right-0 bg-white shadow-md z-50 p-4 flex justify-between items-center h-20 px-20">
        <img src={logo} alt="Vise Logo" className="w-20 h-auto" />
        <nav className="flex space-x-16">
          <button
            onClick={() => scrollToSection("innovation")}
            className="text-gray-600 font-light hover:text-[#62BF04] transition-colors"
          >
            Inovação
          </button>
          <button
            onClick={() => scrollToSection("steps")}
            className="text-gray-600 font-light hover:text-[#62BF04] transition-colors"
          >
            Etapas
          </button>
          <button
            onClick={() => scrollToSection("testimonials")}
            className="text-gray-600 font-light hover:text-[#62BF04] transition-colors"
          >
            Depoimentos
          </button>
        </nav>
      </header>

      <main className="max-w-screen-xl mx-auto px-4 pt-20">
        {/* Sessão principal */}
        <section className="flex flex-col md:flex-row justify-between items-center mt-16 mx-16">
          <div className="flex-1">
            <img
              src={logo}
              alt="Vise Logo"
              className="w-60 h-auto mb-8 animate-fade-in"
            />
            <p className="mt-6 text-2xl font-light text-gray-700 max-w-lg animate-slide-in-left">
              Tecnologia de forma simples, acessível e humana.{" "}
              <strong>Vise</strong>, seu assistente virtual de moda.
            </p>
            <button
              type="button"
              className="mt-8 py-3 px-6 bg-[#62BF04] text-white rounded-full shadow-lg hover:bg-[#4A9902] transition-transform hover:scale-110"
            >
              Explorar
            </button>
          </div>
          <div className="flex-1 flex justify-center items-center relative mt-12 md:mt-0">
            <div className="relative flex space-x-4">
              <div className="relative animate-slide-in-right">
                <img
                  src={closetImg}
                  alt="Closet"
                  className="w-72 h-auto rounded-t-full shadow-xl hover:scale-105 transition-transform"
                  style={{ clipPath: "ellipse(50% 60% at 50% 40%)" }}
                />
              </div>

              <div className="absolute top-20 right-[-150px] z-10 animate-slide-in-right-delayed">
                <img
                  src={modelImg}
                  alt="Modelo"
                  className="w-56 h-auto rounded-t-full shadow-lg hover:scale-105 transition-transform"
                  style={{ clipPath: "ellipse(50% 60% at 50% 40%)" }}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Sessão de Inovação e Tecnologia */}
        <section
          id="innovation"
          className="bg-gradient-to-r from-[#62BF04] to-[#4A9902] text-white py-20 px-10 mt-40 rounded-3xl shadow-xl mb-10 mx-16"
        >
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-screen-xl mx-auto">
            <div className="text-center hover:scale-105 transition-transform">
              <h2 className="text-3xl font-bold mb-4">Tecnologia</h2>
              <p>
                Quando você imaginou que a tecnologia iria impactar na forma com
                a qual você se veste?
              </p>
            </div>
            <div className="text-center hover:scale-105 transition-transform">
              <h2 className="text-3xl font-bold mb-4">Inovação</h2>
              <p>
                Descubra as mil e uma combinações de roupa que seu guarda-roupa
                pode formar.
              </p>
            </div>
            <div className="text-center hover:scale-105 transition-transform">
              <h2 className="text-3xl font-bold mb-4">Confiabilidade</h2>
              <p>
                Um companheiro na hora de decidir o look para aquela ocasião
                especial.
              </p>
            </div>
          </div>
        </section>

        {/* Etapas de uso - Melhorada */}
        <section
          id="steps"
          className="py-20 bg-white text-gray-700 mt-24 mb-24 relative"
        >
          <h2 className="text-4xl font-bold mb-20 text-center">
            Como funciona?
          </h2>

          <div className="max-w-screen-lg mx-auto">
            <div className="relative">
              {/* Linha central modificada para ficar atrás das bolinhas */}
              <div className="absolute w-0.5 bg-[#494949] h-full left-1/2 transform -translate-x-1/2 z-0"></div>

              {/* Etapa 1 */}
              <div className="mb-10 flex flex-col md:flex-row items-center justify-center hover:scale-105 transition-transform">
                <div className="w-full md:w-1/2 text-right pr-10 order-2 md:order-1">
                  <h3 className="text-2xl font-semibold mb-2">Etapa 1</h3>
                  <p className="text-lg">
                    Tire uma foto ou escolha um look do seu guarda-roupa.
                  </p>
                </div>
                <div className="relative z-10 w-8 h-8 bg-[#62BF04] rounded-full flex items-center justify-center text-white text-l font-bold shadow-md order-1 md:order-2">
                  <i className="bi bi-camera"></i>{" "}
                </div>
                <div className="w-full md:w-1/2 text-left pl-10 hidden md:block order-3"></div>
              </div>

              {/* Etapa 2 */}
              <div className="mb-10 flex flex-col md:flex-row-reverse items-center justify-center hover:scale-105 transition-transform">
                <div className="w-full md:w-1/2 text-left pl-10 order-2 md:order-1">
                  <h3 className="text-2xl font-semibold mb-2">Etapa 2</h3>
                  <p className="text-lg">
                    A Vise analisa as suas peças usando IA de ponta.
                  </p>
                </div>
                <div className="relative z-10 w-8 h-8 bg-[#62BF04] rounded-full flex items-center justify-center text-white text-l font-bold shadow-md order-1 md:order-2">
                  <i className="bi bi-cpu"></i>
                </div>
                <div className="w-full md:w-1/2 text-right pr-10 hidden md:block order-3"></div>
              </div>

              {/* Etapa 3 */}
              <div className="mb-10 flex flex-col md:flex-row items-center justify-center hover:scale-105 transition-transform">
                <div className="w-full md:w-1/2 text-right pr-10 order-2 md:order-1">
                  <h3 className="text-2xl font-semibold mb-2">Etapa 3</h3>
                  <p className="text-lg">
                    Receba recomendações instantâneas de combinações.
                  </p>
                </div>
                <div className="relative z-10 w-8 h-8 bg-[#62BF04] rounded-full flex items-center justify-center text-white text-l font-bold shadow-md order-1 md:order-2">
                  <i className="bi bi-check2-circle"></i>
                </div>
                <div className="w-full md:w-1/2 text-left pl-10 hidden md:block order-3">
                </div>
              </div>

              {/* Etapa 4 */}
              <div className="mb-10 flex flex-col md:flex-row-reverse items-center justify-center hover:scale-105 transition-transform">
                <div className="w-full md:w-1/2 text-left pl-10 order-2 md:order-1">
                  <h3 className="text-2xl font-semibold mb-2">Etapa 4</h3>
                  <p className="text-lg">
                    Organize seu guarda-roupa virtual com facilidade.
                  </p>
                </div>
                <div className="relative z-10 w-8 h-8 bg-[#62BF04] rounded-full flex items-center justify-center text-white text-l font-bold shadow-md order-1 md:order-2">
                  <i className="bi bi-phone"></i>
                </div>
                <div className="w-full md:w-1/2 text-right pr-10 hidden md:block order-3"></div>
              </div>
            </div>
          </div>
        </section>

        {/* Novidades */}
        <section className="py-20 from-gray-100 to-gray-200 text-gray-700 rounded-3xl relative">
          <div className="max-w-screen-lg mx-auto grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Personalização Completa */}
            <div className="flex items-center bg-white p-8 rounded-xl shadow-lg transform hover:scale-105 transition-transform hover:shadow-2xl relative overflow-hidden">
              <div className="w-20 h-20 bg-[#62BF04] rounded-full flex items-center justify-center mb-4 text-white text-3xl absolute -left-10 top-10 rotate-45">
                🎨
              </div>
              <div className="ml-12">
                <h3 className="text-2xl font-bold">Personalização Completa</h3>
                <p className="mt-2 text-lg">
                  Looks que combinam com seu estilo e guarda-roupa.
                </p>
              </div>
            </div>

            {/* Tendências Atualizadas */}
            <div className="flex items-center bg-white p-8 rounded-xl shadow-lg transform hover:scale-105 transition-transform hover:shadow-2xl relative overflow-hidden">
              <div className="w-20 h-20 bg-[#62BF04] rounded-full flex items-center justify-center mb-4 text-white text-3xl absolute -left-10 top-10 rotate-45">
                🌟
              </div>
              <div className="ml-12">
                <h3 className="text-2xl font-bold">Tendências Atualizadas</h3>
                <p className="mt-2 text-lg">
                  Receba sugestões atualizadas baseadas nas últimas tendências.
                </p>
              </div>
            </div>

            {/* Economize Tempo */}
            <div className="flex items-center bg-white p-8 rounded-xl shadow-lg transform hover:scale-105 transition-transform hover:shadow-2xl relative overflow-hidden">
              <div className="w-20 h-20 bg-[#62BF04] rounded-full flex items-center justify-center mb-4 text-white text-3xl absolute -left-10 top-10 rotate-45">
                ⏰
              </div>
              <div className="ml-12">
                <h3 className="text-2xl font-bold">Economize Tempo</h3>
                <p className="mt-2 text-lg">
                  Chega de perder tempo escolhendo o que vestir. Deixe que a
                  Vise faça isso por você.
                </p>
              </div>
            </div>

            {/* Sustentabilidade */}
            <div className="flex items-center bg-white p-8 rounded-xl shadow-lg transform hover:scale-105 transition-transform hover:shadow-2xl relative overflow-hidden">
              <div className="w-20 h-20 bg-[#62BF04] rounded-full flex items-center justify-center mb-4 text-white text-3xl absolute -left-10 top-10 rotate-45">
                🌿
              </div>
              <div className="ml-12">
                <h3 className="text-2xl font-bold">Sustentabilidade</h3>
                <p className="mt-2 text-lg">
                  Faça escolhas inteligentes e sustentáveis com base no que você
                  já possui.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Depoimentos */}
        <section id="testimonials" className="py-20 bg-white text-gray-700">
          <div className="max-w-screen-lg mx-auto text-center">
            <h2 className="text-4xl font-bold mb-10">
              O que os nossos clientes dizem
            </h2>
            <div className="relative">
              <div className="carousel flex space-x-4 overflow-hidden">
                <div className="w-1/3 p-4 hover:scale-105 transition-transform">
                  <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                    <p className="italic">
                      "Com a Vise, eu economizo tempo todas as manhãs. É como
                      ter um estilista pessoal ao meu lado!"
                    </p>
                    <h4 className="mt-4 text-xl font-semibold">Ana, 28</h4>
                  </div>
                </div>
                <div className="w-1/3 p-4 hover:scale-105 transition-transform">
                  <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                    <p className="italic">
                      "Eu nunca imaginaria tantas combinações de roupas com o
                      que já tenho. A Vise é incrível!"
                    </p>
                    <h4 className="mt-4 text-xl font-semibold">Carlos, 34</h4>
                  </div>
                </div>
                <div className="w-1/3 p-4 hover:scale-105 transition-transform">
                  <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
                    <p className="italic">
                      "Finalmente consigo organizar meu guarda-roupa de forma
                      eficiente e sustentável. Vise mudou minha vida."
                    </p>
                    <h4 className="mt-4 text-xl font-semibold">Maria, 40</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      </div>
    </>
  );
}

export default App;
